import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../logo.png";
import MDL_CONFIRMATION from "../../components/cmp-confirmation/cmp-confirmation";
import MDL_UPDATE_PASSWORD from "../../components/mdl-update-password/mdl-scheduler-update-password";

import "./Header.css";
import { Button, Dropdown, DropdownItem, DropdownMenu, Icon, Modal, Popup, Search } from "semantic-ui-react";
import { AppContext } from "../../Context/AppContext";
import { buildingStat, getBuildingLookup } from "../../constants";
import RequestOrderIcon from "./RequestOrderIcon";
import { result } from "lodash";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Header({ user, setUser, building_info, setBuildingInfo, navToggle }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { orderCunt, selectedBuilding, sliderCart, cartItems,checkedUnitsData, selected_units,ShowHeaderSearch,BuildingUpdate } =
    useContext(AppContext);
  const [, setOrderCount] = orderCunt;
  const [, setSideSliderCart] = sliderCart;
  const [,setCartItems] = cartItems;
  const [,setCheckedUnits] = checkedUnitsData;
  const [, set_add_unit] = selected_units;
  const [request_btn_hover, set_request_btn_hover] = useState("red");
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [mdl_update_password_open, set_mdl_update_password_open] =
    useState(false);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ShowCart, setShowCart] = useState(false);
  const [query, setQuery] = useState("");
  const timeoutRef = React.useRef();
  const [buildingUpdate,setBuildingUpdate] = BuildingUpdate;
  const [var_ShowHeaderSearch, setShowHeaderSearch]= ShowHeaderSearch

  const location = useLocation();

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  function onOpen_mdl_update_password() {
    set_mdl_update_password_open(true);
  }
  useState(() => {
    const { role } = user;
    if (role && role === "customer") getBuildingStat();
  }, [building_info]);

  const handleSearchChange = React.useCallback((e, data) => {
    setQuery(data.value)
  }, []);

  useEffect(()=>{ },)

  React.useEffect(() => {
    
    timeoutRef.current = setTimeout(() => {
        if (query) {
          fetchBuildingInfo(query);
        }
      }, 300);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [query]);


  useEffect(() => {
    const unlisten = history.listen((location) => {
      // This will be called every time the route changes
      if(location.pathname.includes("building-history/")){
        setShowCart(true)
      }else{
        setShowCart(false)
      }
      // Trigger any state updates or effects as needed
    });
    return () => {
      unlisten(); // Cleanup the listener on unmount
    };
  }, [history]);
  /**
   *
   */
  async function fetchBuildingInfo(searchQuery) {
    setIsLoading(true);
    try {
      const response = await fetch(getBuildingLookup + `?q=${searchQuery}`);
      if (!response.ok) {
        throw new Error("Failed to fetch building info");
      }
      const data = await response.json();
      if (data.code == 200) {
        const updatedData = data.data.map((building) => ({
          ...building,
          title: `${building?.location_number} - ${building.text} - ${building?.customer_name}`
        }));

        setSearchResults(updatedData);
      } else {
        console.log("Else data:::", data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching building info:", error);
    }
  }

  /**
   * Get the stats of building
   */
  async function getBuildingStat() {
    if (building_info && building_info.uuid) {
      await fetch(buildingStat + `${building_info.uuid}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.code === 200) setOrderCount(res);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setBuildingInfo(null);
    }
  }
  const getInitials = function (string) {
    if (string) {
      var names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    } else {
      return "";
    }
  };

  function handleClick() {
    // if (cart_items) {
    setSideSliderCart(true);
    // } else {
    //   set_add_unit([{ unit_number: "", followup: "neworder", key: 0 }]);
    //   history.push(`/order-request/${selected_building?.location_id}`);
    // }
  }

  async function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
  }

  function resultSelect(e, data){
    history.push(`/building-impersonate/${data.result.value}`)
  }

  function onChangeBuilding(event, { value }) {
    setSelectedBuilding(building_info.filter((f_v) => f_v.value === value)[0]);
    setBuildingUpdate(true);
    setCartItems(null);
    setCheckedUnits({});
    set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return user ? (
    user.role === "customer" && building_info?.length === 0 ? null : (
      <header className="app-header">
        <div className="app-container">
          <div className="app-header-inner">
            <div className="app-header-logo">
              <Link
                to={
                  user.role === "customer"
                    ? `/home?s_id=${localStorage.getItem("s_id")}`
                    : user.role === "technician"
                    ? "/technician-home"
                    : user.role === "admin"
                    ? "/dashboard"
                    : "/scheduler-home"
                }
                onClick={() => {
                  setShowHeaderSearch(!var_ShowHeaderSearch);
                }}
              >
                <img src={Logo} alt={`${var_ShowHeaderSearch}PestPac`} />
              </Link>
              {user.role === "admin" && (
                <div className="mobile-nav-toggler" onClick={() => navToggle()}>
                  <img src="./hamburger.svg" alt="" />
                </div>
              )}

            {building_info?.length > 0 && location?.pathname === '/home' && (
              <Dropdown
                search
                style={{width:'290px'}}
                selection
                // className="sortby_options"
                id="sortby_optionss"
                // name="sortby_options"
                // input={<OutlinedInput label="Tag" />}
                placeholder="Select Building"
                label="Building: "
                name="building"
                onChange={onChangeBuilding}
                value={selected_building?.value}
                options={building_info}
              />
            )}
              {user.role === "scheduler" &&
                    !history.location.pathname.includes("/building-impersonate/") && !history.location.pathname.includes("/critical-section") && !history.location.pathname.includes("/building-heatmap") && (
              <div className="app-content-search">
                <Search
                  loading={isLoading}
                  onResultSelect={resultSelect}
                  results={searchResults}
                  value={query}
                  placeholder="Search Building or Location #"
                  onSearchChange={handleSearchChange}
                />
              </div>
              )}
            </div>
            <div className="app-header-content">
              <div className="app-header-user">
              <div
                  className="loggedin-user"
                  style={{ marginTop: user.role === "scheduler" ? "10px" : "0px" }}
                >
                  {user.role === "scheduler" && (
                    <div
                      className="update-pass"
                      name="Update Password"
                      onClick={() => onOpen_mdl_update_password()}
                      
                    >
                      <img
                        src="./icon-padlock.svg"
                        alt="Password"
                        width={15}
                        height={15}
                      />
                      <span>Update Password</span>
                    </div>
                  )}
                    
                  {user.role === "technician" && (
                    <div
                      className="update-pass"
                      name="Update Password"
                      onClick={() => onOpen_mdl_update_password()}
                      
                    >
                      <img
                        src="./icon-padlock.svg"
                        alt="Password"
                        width={15}
                        height={15}
                      />
                      <span>Update Password</span>
                    </div>
                  )}
                  {/* {user.role !=='customer'&& <div className="loggedin-user-short">
                   
                     <div title={user.firstName + " " + user.lastName}>
                        {user.firstName && `${user.firstName[0]}`}{" "}
                        {user.lastName && `${user.lastName[0]}`}
                      </div>
                    
                  </div>} */}
                  {user.role === "customer"&& <Dropdown style={{marginRight:'10px',marginTop:'4px'}} className='account-dropdown link item' text={<><Icon size="big" name="user circle"/><span style={{fontSize:'16px'}}>Account</span></>} floating  >
                    <DropdownMenu>
                      <DropdownItem>
                      {user.role === "customer" && (
                    <div
                      className="requests-contain"
                      name="Documents"
                      onClick={() => history.push("/documents")}
                    >
                      {/* <img
                        title="Documents"
                        src={`${window.location.origin}/folder-icon.png`}
                        alt="documents"
                      /> */}
                      <Icon name="folder" color="grey" inverted size="big"/>
                      Documents
                    </div>
                  )}
                        </DropdownItem>
                      <DropdownItem style={{paddingLeft:'0!important',paddingRight:'0!important'}}>
                      <div
                      className="logout-content"
                      name="Logout"
                      onClick={() => set_mdl_confirmation(true)}
                    >
                      {/* <span className="logout-img">
                        <img src="./logout.svg" alt="Logout" />
                      </span> */}
                      <Icon size="big" color='grey' inverted name="log out"/>
                      <span style={{fontSize:'14px'}}>Logout</span>
                    </div>
                        </DropdownItem>
                      </DropdownMenu>
                  </Dropdown>}
                  {(user.role === "customer" && 
                    (history.location.pathname === "/home" || 
                    window.location.pathname.includes("building-history/") || ShowCart)) && (
                    <div
                      onMouseEnter={() => {
                        set_request_btn_hover("white");
                      }}
                      onMouseLeave={() => {
                        set_request_btn_hover("red");
                      }}
                      className="request-btn-div"
                    >
                      <Button
                        className="btn-request-header"
                        inverted
                        color={"red"}
                        onClick={() => handleClick()}
                      >
                        <RequestOrderIcon fill={request_btn_hover} />
                       <span style={{padding:'0px 12px 0px 0px',fontWeight:'bold',fontSize:'16px'}}> Cart</span>
                      </Button>
                    </div>
                  )}
                  {user.role !== "admin" && user.role!=='customer' && (
                    <div
                      className="logout-content"
                      name="Logout"
                      onClick={() => set_mdl_confirmation(true)}
                    >
                      <span className="logout-img">
                        <img src="./logout.svg" alt="Logout" />
                      </span>
                      <span>Logout </span>
                      <Icon size="big" color='grey' inverted name="log out"/>
                    </div>
                  )}
                  {/* <div className="loggedin-username">
                                    {user.role==="customer"?"" : user.firstName+ " " +user.lastName }
                                </div> */}
                </div>
              </div>
            </div>
          </div>
          {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
          <Modal
            id="mdl-confirmation"
            dimmer={"inverted"}
            open={var_mdl_confirmation}
            onClose={() => set_mdl_confirmation(false)}
          >
            <MDL_CONFIRMATION
              set_mdl_open={set_mdl_confirmation}
              var_modaltitle="Logout"
              var_message="Are you sure you want to log out?"
              confirmation_function={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("building");
                setSelectedBuilding(null);
                setCartItems(null);
                setCheckedUnits({});
                set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
                if (user.role === "customer") {
                  setBuildingInfo(null);
                  history.replace("/");
                  localStorage.clear();
                } else if (user.role === "technician") {
                  history.replace("/technician");
                } else {
                  history.replace("/scheduler");
                }
                setUser(null);
              }}
            ></MDL_CONFIRMATION>
          </Modal>

          {/***** MODAL: UPDATE PASSWORD - SCHEDULER **************************************************************************/}
          <Modal
            id="mdl-forgot-pin-customer"
            dimmer={"inverted"}
            open={mdl_update_password_open}
            onClose={() => set_mdl_update_password_open(false)}
          >
            <MDL_UPDATE_PASSWORD
              set_mdl_open={set_mdl_update_password_open}
            ></MDL_UPDATE_PASSWORD>
          </Modal>

          {/***** END MODAL: UPDATE PASSWORD - SCHEDULER **************************************************************************/}
        </div>
      </header>
    )
  ) : null;
}

export default Header;
