import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Icon,
  Label,
  Button,
  Grid,
  Modal,
  Loader,
} from "semantic-ui-react";
import "../../BuildingHeatmap.css";
import { AppContext } from "../../../../../Context/AppContext";
import {
  getUnitSummaryAPI,
  deleteOrder,
  getAllPestsTreatment,
  getAllPests,
} from "../../../../../constants";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MDL_CONFIRMATION from "../../../../../components/cmp-confirmation/cmp-confirmation";
import moment from "moment";
import { uniqueId } from "lodash";

const MDL_UNIT_HISTORY = ({ set_mdl_open, unit_number,filter,pestValue,building_id, set_selected_unit,set_refresh_heatmap }) => {
  const { selectedBuilding, cartItems, sliderCart, checkedUnitsData,userInfo } =
    useContext(AppContext);
    const [user] = userInfo;
  const [selected_building] = selectedBuilding;
  const [cart_items, setCartItems] = cartItems;
  const [, setSideSliderCart] = sliderCart;
  const [checkedUnits, setCheckedUnits] = checkedUnitsData;
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [building_completed, setBuilding_completed] = useState([]);
  const [building_upcomming, setBuilding_upcomming] = useState([]);
  const [building_unit, setBuilding_unit] = useState([]);
  const [var_loading, set_loading] = useState(false);
  const [var_disabled, set_disabled] = useState(false);
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [var_delete_Parms, setdeleteParms] = useState(null);
  const history = useHistory();
  const { addToast } = useToasts();
  const checkCartItems = (floor, unitNumber) => {
    if (cart_items && cart_items[floor]) {
      return cart_items[floor]?.some((item) => item.unit_number === unitNumber);
    }
    return false;
  };

  useEffect(() => {
    if (unit_number) getUnitSummary();
  }, [unit_number]);

  useEffect(() => {
    const floorToCheck = building_unit[0]?.floor;
    var unitNumberToCheck = building_unit[0]?.unit;
    const isPresent = checkCartItems(floorToCheck, unitNumberToCheck);
    if (isPresent || building_unit.length === 0) {
      set_disabled(true);
    } else {
      set_disabled(false);
    }
  }, [building_unit]);

  async function getUnitSummary() {
    set_loading(true);
    var selectedBuildingId = selected_building?.value
      ? selected_building?.value
      : building_id;
    var pestFilter = "";
    if(pestValue !== "all"){
      pestFilter = `&pestID=${pestValue}`;
    }
    let API_URL =
      getUnitSummaryAPI + selectedBuildingId + `?unit=` + unit_number+`&month=${filter ? filter : '3'}${pestFilter}`;

    await fetch(API_URL)
      .then((response) => response.json())
      .then((response) => {
        if (response?.data?.completedOrders) {
          setBuilding_completed(response?.data?.completedOrders);
          setBuilding_upcomming(response?.data?.inProgressScheduleOrders);
          setBuilding_unit(response?.data?.unitDetail);
        } else {
          setBuilding_completed([]);
        }
        set_loading(false);
      });
  }

  function closeModal() {
    set_selected_unit(null);
    set_mdl_open(false);
  }

  function goViewDetail(id, unit_number) {
    history.push({ pathname: "/order-detail", state: { id, unit_number } });
  }

  function deleteOrderConfirmation(
    unit_uuid,
    order_number,
    scheduler_email,
    building_name
  ) {
    var params = {};
    params = { ...params, order_unit_uuid: unit_uuid };
    params = { ...params, scheduler_email: scheduler_email };
    params = { ...params, order_number: order_number };
    params = { ...params, building_name: building_name };
    setdeleteParms(params);
    set_mdl_confirmation(true);
  }

  function deleteOrderApiCall() {
    const requestOptionsOrder = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(var_delete_Parms),
    };
    set_mdl_progress(true);
    fetch(deleteOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "error",
          autoDismiss: true,
        });
        set_mdl_confirmation(false);
        set_mdl_open(false);
        set_refresh_heatmap(true);
      })
      .catch((error) => {
        console.log("Error:::::", error);
      });
  }

  async function getPestNameFromComments(splitCommentWithFollowUp) {
    let pest = "";
  
    // Convert input to lowercase to make the function case-insensitive
    const commentLower = splitCommentWithFollowUp.toLowerCase();
    
    // Define an array of keywords for each pest
    const pests = {
      "Crawling Insects (Spiders, Silverfish, Centipedes, etc.)": ["crawling insects", "crawling insect", "crawling ins", "crawling"],
      "Bed Bugs": ["bb", "bed bug", "bed bugs", "bedbugs", "bedbug", "bed"],
      "Cockroaches": ["cr", "cockroach", "cockroaches"],
      "Mice": ["mice"],
      "Rats": ["rat", "rats"],
      "Pharaoh Ants": ["pharaoh ants", "pharaoh ant", "pharaoh"],
      "Ants": ["ant", "ants"],
      "Other Pest": ["other pest", "other", "general", "general pest"],
      "Fleas": ["fleas", "flea"],
      "Flies": ["flies", "flie"],
      "Stored Product Pests (Beetles, Weevils, etc.)": [
        "stored product pests",
        "stored product pest",
        "stored pests",
        "stored pest",
        "stored product",
        "stored"
      ]
    };
  
    // Check each pest category in the defined order
    for (const [key, keywords] of Object.entries(pests)) {
      for (const keyword of keywords) {
        if (commentLower.includes(keyword.toLowerCase())) {
          pest = key;
          break;
        }
      }
      if (pest) break; // Exit the loop if pest is found
    }
    return pest;
  }

  async function getTreatmentFromComments(comment) {
    let treatment = "";
    // Convert input to lowercase to make the function case-insensitive
    const commentLower = comment.toLowerCase();
    // Define an array of keywords for each treatment
    const treatments = {
      "Inspection": ["inspection"],
      "Residual Spray Treatment": ["residual spray", "residual spray treatment", "residual treatment"],
      "Spray Treatment": ["spray", "spray treatment"],
      // "Flush and Vac Treatment": ["flush and vac", "flush and vac treatment", "spray, flush and vac treatment"],
      "Gel and Dust Treatment": ["dust and gel", "gel and dust treatment", "dust and gel treatment"],
      "Monitor Installation": ["monitor installation"],
      "Preventative Treatment": ["preventative", "preventative treatment"],
      "Exacticide Treatment": ["exacticide", "exacticide treatment"],
      "Bait Treatment": ["bait", "bait treatment"],
      "BioShield Treatment": ["bioshield", "bioshield treatment"],
      "Actisol Treatment": ["actisol", "actisol treatment"],
      "Treatment": ["treatment"],
    };
    // Check each treatment category
    for (const [key, keywords] of Object.entries(treatments)) {
      if (keywords.some(keyword => commentLower.includes(keyword.toLowerCase()))) {
        treatment = key;
        break;
      }
    }
    return treatment;
  }

  async function getPestTreatments(treatmentName) {
    var treatmentID = "";
    await fetch(getAllPestsTreatment)
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          const treatment = res.data.find(
            (p) =>
              p.treatmentName?.toLowerCase() === treatmentName.toLowerCase()
          );
          if (treatment) {
            treatmentID = treatment.treatmentId;
          }
        }
      });
    return treatmentID;
  }
  async function getPests(pestName) {
    var pestID = "";
    await fetch(getAllPests)
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          const pest = res.data.find(
            (p) => p.pestName?.toLowerCase() === pestName.toLowerCase()
          );
          if (pest) {
            pestID = pest.pestId;
          }
        }
      });
    return pestID;
  }

  async function commentData(comment, pest_name, treatment_name) {
    var pest = "";
    var treatment = "";
    var pestName = "";
    var treatmentName = "";
    var techCommentDetails = "neworder";
    var commentsLowerCase = comment?.toLowerCase() || "";
    if (
      commentsLowerCase.includes("follow up") &&
      !commentsLowerCase.includes("no follow up")
    ) {
      if (commentsLowerCase.includes("follow up inspection") || commentsLowerCase.includes("follow-up inspection") || commentsLowerCase.includes("follow-up inspection recommended") || commentsLowerCase.includes("follow up inspection recommended")) {
            pestName =pest_name;
            treatmentName = "Inspection"
            pest = await getPests(pest_name);
            treatment = await getPestTreatments(treatmentName);
            techCommentDetails = "followup";
      } else if (commentsLowerCase.includes("follow up treatment") || commentsLowerCase.includes("follow-up treatment") || commentsLowerCase.includes("follow-up treatment recommended") || commentsLowerCase.includes("follow up treatment recommended")) {
            pestName =pest_name;
            treatmentName = "Treatment"
            pest = await getPests(pest_name);
            treatment = await getPestTreatments(treatmentName);
            techCommentDetails = "followup";
      }else{
        var commentFollowUp = commentsLowerCase.split("follow up");
        if (commentFollowUp[1]) {
          pestName = await getPestNameFromComments(commentFollowUp[1]);
          treatmentName = await getTreatmentFromComments(commentFollowUp[1]);
          if (pestName) {
            pest = await getPests(pestName);
            treatment = await getPestTreatments(treatmentName);
          }
          techCommentDetails = "followup";
        }
      }
    } else if (commentsLowerCase.includes("not completed")) {
      if (pest_name && treatment_name) {
        pest = await getPests(pest_name);
        treatment = await getPestTreatments(treatment_name);
      }
      techCommentDetails = "followup_immediately";
    } else if (commentsLowerCase.includes("reschedule required")) {
      if (pest_name && treatment_name) {
        pest = await getPests(pest_name);
        treatment = await getPestTreatments(treatment_name);
      }
      techCommentDetails = "reschedule";
    }else if (commentsLowerCase.includes("no follow up")) {
      if (pest_name && treatment_name) {
        pest = await getPests(pest_name);
        treatment = await getPestTreatments(treatment_name);
      }
    } 

    return [pest, pestName, treatment, treatmentName, techCommentDetails];
  }

  async function addUnittoOrder() {
    var unit_number = building_unit[0]?.unit;
    var floor = building_unit[0]?.floor;
    var [pest, pestName, treatment, treatmentName, techCommentDetails] = "";
    var appendedObject = {
      unit_number,
      followup: "neworder",
      floor,
      key: uniqueId(),
    };
    const sortedOrders = [...building_completed].sort(
      (a, b) => new Date(b.work_date) - new Date(a.work_date)
    );
    const latest = sortedOrders[0];
    if (latest?.comment) {
      var pest_name = latest?.pest_name;
      var treatment_name = latest?.treatment_name;
      var comment = latest?.comment;
      [pest, pestName, treatment, treatmentName, techCommentDetails] =
        await commentData(comment, pest_name, treatment_name);
      appendedObject = {
        unit_number,
        followup: techCommentDetails,
        pest: pestName ? pestName : pest_name,
        treatment: treatmentName ? treatmentName : treatment_name,
        pest_id: pest,
        treatment_id: treatment,
        floor,
        key: uniqueId(),
      };
    }
    var cartData = cart_items ? { ...cart_items } : {};
    //If cartData is null then we have no need to compare the floor
    if (!cartData || !Object.keys(cartData).includes(floor)) {
      cartData = { ...cartData, [floor]: [appendedObject] };
    }
    //Now the turn is cartData have some data
    else {
      //If floor that is clicked exist in
      if (Object.keys(cartData).includes(floor)) {
        var floorData = cartData[floor];
        floorData = [...floorData, appendedObject];
        cartData = { ...cartData, [floor]: floorData };
      } else {
        cartData = { ...cartData, [floor]: [appendedObject] };
      }
    }
    var checkedfloor = floor + "-" + unit_number;
    setCheckedUnits({ ...checkedUnits, [checkedfloor]: true });
    setCartItems(cartData);
    setSideSliderCart(true);
    set_mdl_open(false);
  }

  return (
    <>
      <Form>
        <div className="modal-header">
          <div className="modal-header-title">Unit# {unit_number} History</div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content" style={{ overflow: "scroll" }}>
          <Grid
            style={{
              margin: "0",
              overflowY: "auto",
              whiteSpace: "nowrap",
              minWidth: "1300px",
              height: "30px",
              fontSize: "15px",
              fontWeight: "bold",
              borderBottom: "1px solid #e8e8e8",
            }}
          >
            Upcoming Orders
          </Grid>
          <Grid
            style={{
              margin: "0",
              overflowY: "auto",
              whiteSpace: "nowrap",
              minWidth: "1300px",
            }}
          >
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Order #</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Status</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={5}>
              <div className="order-history-item">
                <span>Treatment</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Work Date</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Time Window</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={3}></Grid.Column>

            {!var_loading &&
              building_upcomming?.map((summary) => (
                <>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.order_number}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span style={{ textTransform: "capitalize" }}>
                        {summary.order_status || "No Status"}
                      </span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <div className="order-history-item">
                      <span></span>
                      <span>
                        {summary.pest_name} {summary.treatment_name}
                      </span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>
                        {summary.work_date
                          ? moment(summary.work_date).format("MM-DD-YYYY")
                          : "N/A"}
                      </span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.time_range || "N/A"}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={3}>
                  {user.role === "customer" && (
                    <>
                    <Label
                      color="red"
                      onClick={() =>
                        deleteOrderConfirmation(
                          summary.uuid,
                          summary.order_number,
                          summary.scheduler_email,
                          summary.building_name
                        )
                      }
                    >
                      Delete
                    </Label>
                    <Label
                      color="blue"
                      onClick={() =>
                        goViewDetail(
                          summary.service_order_id,
                          summary.unit_number
                        )
                      }
                    >
                      View
                    </Label>
                    </>
                  )}
                  </Grid.Column>
                </>
              ))}
            <Grid.Column width={16}>
              {var_loading && <Loader active />}
            </Grid.Column>
            {!var_loading && building_upcomming.length === 0 && (
              <Grid.Column width={16}>
                <div className="order-history-item">
                  <span></span>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>No Data</span>
                  </div>
                </div>
              </Grid.Column>
            )}
          </Grid>
          <Grid
            style={{
              margin: "0",
              overflowY: "auto",
              whiteSpace: "nowrap",
              minWidth: "1300px",
              height: "45px",
              fontSize: "15px",
              fontWeight: "bold",
              borderBottom: "1px solid #e8e8e8",
              display: "flex", // Added flexbox for alignment
              justifyContent: "space-between", // Space between items
              alignItems: "center", // Vertically center items
              paddingRight: "15px", // Add some space to the right
            }}
          >
            Completed Orders
            {user.role === "customer" && (
            <Button color={var_disabled ? "green" :"blue"} style={{padding:"10px",fontSize:"13px"}} onClick={() => addUnittoOrder()} disabled={var_disabled}>
            {var_disabled ? (
              <>
                <Icon
                  name="check"
                  style={{
                    alignSelf: "center",
                    margin: "0px",
                    fontSize: "15px",
                    marginRight: "10px",
                  }}
                />
                Unit Added to Cart
              </>
            ) : (
              "Add Unit to Order"
            )}
          </Button>
          )}
          </Grid>
          <Grid
            style={{
              margin: "0",
              overflowY: "auto",
              whiteSpace: "nowrap",
              minWidth: "1300px",
            }}
          >
            <Grid.Column computer={1}>
              <div className="order-history-item">
                <span>Order #</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={3}>
              <div className="order-history-item">
                <span>Treatment</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Work Date</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Time Window</span>
              </div>
            </Grid.Column>

            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Activity Level</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Prep. Level</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Sanitation Issue</span>
              </div>
            </Grid.Column>
            <Grid.Column computer={2}>
              <div className="order-history-item">
                <span>Structural Issue</span>
              </div>
            </Grid.Column>
            {!var_loading &&
              building_completed?.map((summary) => (
                <>
                  <Grid.Column computer={1}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.order_number}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={3}>
                    <div className="order-history-item">
                      <span></span>
                      <span style={{textWrap: 'wrap'}}>
                        {summary.pest_name} {summary.treatment_name}
                      </span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>
                        {moment(summary.work_date).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.time_range || "N/A"}</span>
                    </div>
                  </Grid.Column>

                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.activity_level === 'Low activity' ? 'Light Activity' : summary.activity_level === 'High activity' ? 'Heavy Activity' : summary.activity_level === 'Moderate activity' ? 'Moderate Activity' : 'N/A'}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.prep_level || 'N/A'}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>{summary.sanitation_level ? 'Yes' : 'No'}</span>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={2}>
                    <div className="order-history-item">
                      <span></span>
                      <span>
                        {summary.structural_issues ? 'Yes' : 'No'}
                      </span>
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    style={{ whiteSpace: "wrap" }}
                    className="comment-spacing border-bottom comment-text"
                  >
                    <span>{summary.comment || "No Comment"}</span>
                  </Grid.Column>
                </>
              ))}
            <Grid.Column width={16}>
              {var_loading && <Loader active />}
            </Grid.Column>
            {!var_loading && building_completed.length === 0 && (
              <Grid.Column width={16}>
                <div className="order-history-item">
                  <span></span>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>No Data</span>
                  </div>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </div>
        <div className="modal-footer">
          <Button color="red" onClick={() => set_mdl_open(false)}>
            Close
          </Button>
          {/* <Button color="green">Submit</Button> */}
        </div>
      </Form>
      {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
      <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Delete Order"
          var_message="Are you sure want to delete order?"
          confirmation_function={deleteOrderApiCall}
        ></MDL_CONFIRMATION>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
    </>
  );
};

export default MDL_UNIT_HISTORY;
