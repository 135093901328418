import React, {useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Label, Modal } from 'semantic-ui-react';
import MDL_CREATE_CUSTOMER from './mdl-create-customer/mdl-create-customer';
import MDL_UPDATE_CUSTOMER from './mdl-update-customer/mdl-update-customer';
import MDL_CONFIRMATION from '../../../components/cmp-confirmation/cmp-confirmation';
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import { useToasts } from 'react-toast-notifications';
import './CustomerManagement.css';
import { deleteUser, getAllUsers } from '../../../constants';
import AppPagination from '../../../components/cmp-pagination/Pagination';


function CustomerManagement() {


    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ users,setUsers ]=useState([]);
    const [ mdl_createcustomer_open, set_mdl_createcustomer_open ] = useState(false);
    const [ mdl_updatecustomer_open, set_mdl_updatecustomer_open ] = useState(false);
    const [ var_customer_data, set_customer_data ] = useState();
    const [var_mdl_progress, set_mdl_progress] = useState(false);
    const [ deleteIndex,setDeleteIndex ] = useState();
    const [ page,setPage ] = useState(1);
    const [ limit,setLimit ] = useState(50);
    const[,setTotalUsers]=useState(0)
    const [ var_mdl_confirmation, set_mdl_confirmation] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
	useEffect(() => {
        getListOfUsers();
	},[limit,page])

    // useEffect(() => {
    //     getListOfUsers(page,limit);
	// },[limit,page])

    // useEffect(() => {
    //     getListOfUsers(page,limit);
	// },[page])


    /* Async functions
	---------------------------------------------------------------------------------*/
    async function delete_customer(){
        set_mdl_progress(true);
        var user = users[deleteIndex];
        if(user){
            const { uid } = user;
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body:JSON.stringify({customer_id:uid})
            };
            await fetch(deleteUser,requestOptions)
            .then(res=>res.json())
            .then(response=>{
                const{failed,success}=response;
                if(failed) {
                    addToast(failed, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
                if(success) {
                    getListOfUsers(0,limit)
                    addToast(success, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }
            })
            .catch(error=>{
                console.error("Error",error);
            })
        }
        set_mdl_progress(false);
    }

    const updateModel=(value)=>{
        set_mdl_updatecustomer_open(value);
        getListOfUsers()
    }

    const getListOfUsers = async (page_no,limit_range) => {
        set_mdl_progress(true);
        await fetch(getAllUsers+`?is_role=customer&page=${page_no?page_no: page}&limit=${limit_range?limit_range: limit}`)
        .then(response => response.json())
        .then(res => {
            setTotalUsers(res.total_user)
            var usersFetch=[];
            const{data}=res
            if(data.length === 0){
                setPage(page-1)
            }
            data.forEach((element,pos) => {
                usersFetch=[...usersFetch,
                    {
                        uid:element.uid,
                        first_name:element.first_name,
                        last_name:element.last_name,
                        address:element.adds,
                        email:element.email,
                        contact_number:element.phone,
                        role:element.role?element.role:'',
                        key:element.uid,
                        text:element.first_name+" "+element.last_name,
                        value:element.first_name+" "+element.last_name,
                        company:element.company
                    }
                ]
            });
            if( (page && page >1) || (page_no && page_no >1) )
            usersFetch=[...users,...usersFetch];
            setUsers(usersFetch);
        })
        .catch(error=>{console.error("Error:",error)});
        set_mdl_progress(false);
    }

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    function onOpen_mdl_create_customer() {
        set_mdl_createcustomer_open(true)
    }

    function onOpen_mdl_update_customer(customer) {
        set_customer_data(customer);
        set_mdl_updatecustomer_open(true);
    }

    function onOpen_mdl_confirmation(pos) {
        set_mdl_confirmation(true);
        setDeleteIndex(pos);
    }

    const goToViewCustomer = (customer) => {
        history.push({pathname:'/customer-management/view-customer',state:{data:customer}})
    }



    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return(
        <>
            <div className="app-content">

                <div className="app-customer-container">

                    <div className="app-content-action">
                        <div className="app-content-action-right">
                            <Button color="red" onClick={() => onOpen_mdl_create_customer()}>
                                <img src="/plus-icon.svg" alt="icon" />Add Customer
                            </Button>
                        </div>
                    </div>
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">Customer Management ({users.length})</div>
                        </div>

                        <div className="app-table-wrapper">
                            <Table unstackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Customer</Table.HeaderCell>
                                        <Table.HeaderCell>Company</Table.HeaderCell>
                                        <Table.HeaderCell>Email</Table.HeaderCell>
                                        <Table.HeaderCell>Contact #</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                {!var_mdl_progress && users.map((customer, i) =>
                                    <Table.Row key={i}>
                                        <Table.Cell>
                                            <div className="td-customer-name">
                                                <div className="td-short-name">{customer.first_name && customer.first_name[0]}{customer.last_name && customer.last_name[0]}</div>
                                                <div className="td-full-name">{customer.first_name} {customer.last_name}</div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>{customer.company}</Table.Cell>
                                        <Table.Cell>{customer.email}</Table.Cell>
                                        <Table.Cell>{customer.contact_number}</Table.Cell>
                                        <Table.Cell collapsing textAlign='right' className="td-action">
                                            <Label color="blue" onClick={() => goToViewCustomer(customer)}>View</Label>
                                            <Label onClick={() => onOpen_mdl_update_customer(customer)} color="green">Edit</Label>
                                            <Label color="red" onClick={() => onOpen_mdl_confirmation(i)}>Delete</Label>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                </Table.Body>
                            </Table>
                            {!var_mdl_progress && users.length === 0 &&
                            <div className="nodata-placeholder">
                                There are no customers to display.
                            </div>
                        }
                        </div>
                    </div>
                    <AppPagination limit={limit} setLimit={setLimit} page={page} setPage={setPage} rowsTotal={users.length}/>
                </div>

            </div>


            {/***** MODAL: CREATE CUSTOMER **************************************************************************/}
            <Modal id="mdl-create-customer"
                   dimmer={'inverted'}
                   open={mdl_createcustomer_open}
                   onClose={() => set_mdl_createcustomer_open(false)}>
                <MDL_CREATE_CUSTOMER
                    set_mdl_open={set_mdl_createcustomer_open}
                    update_users_list={getListOfUsers}
                    limit={limit}
                    page={page}
                >
                </MDL_CREATE_CUSTOMER>
            </Modal>

            {/***** END MODAL: CREATE CUSTOMER **************************************************************************/}


            {/***** MODAL: UPDATE CUSTOMER **************************************************************************/}
            <Modal id="mdl-update-customer"
                   dimmer={'inverted'}
                   open={mdl_updatecustomer_open}
                   onClose={() => set_mdl_updatecustomer_open(false)}>
                <MDL_UPDATE_CUSTOMER
                    set_mdl_open = {updateModel}
                    customer = {var_customer_data}
                >
                </MDL_UPDATE_CUSTOMER>
            </Modal>

            {/***** END MODAL: UPDATE CUSTOMER **************************************************************************/}


            {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
            <Modal id="mdl-confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation}
                   onClose={() => set_mdl_confirmation(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open = {set_mdl_confirmation}
                    var_modaltitle = "Delete Customer"
                    var_message = "Are you sure want to delete this customer?"
                    confirmation_function = {delete_customer}
                >
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
            {/***** MODAL: PROGRESS **************************************************************************/}
                  <Modal
                    id="mdl-progress"
                    dimmer={"inverted"}
                    open={var_mdl_progress}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    onClose={() => {}}
                  >
                    <MDL_PROGRESS
                      set_mdl_open={set_mdl_progress}
                      var_modaltitle="Please wait"
                      var_message={"Stay on this page until this process finish."}
                    ></MDL_PROGRESS>
                  </Modal>
            
                  {/***** END MODAL: PROGRESS **************************************************************************/}
        </>
    )

}

export default CustomerManagement;