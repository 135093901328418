import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import MDL_UPDATE_PASSWORD from "./mdl-update-password/mdl-update-password";

import "./sidebar.css";

function Sidebar({ navOpen }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const history = useHistory();
  const [mdl_updatepassword_open, set_updatepassword_open] = useState();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const logout_onClick = () => {
    setUser(null);
    history.replace("/admin");
    localStorage.removeItem("user");
    localStorage.removeItem("building");
  };

  const onOpen_mdl_updatePassword = () => {
    set_updatepassword_open(true);
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  //DraftRequestsAdmin
  return (
    <>
      <aside className={"app-sidebar" + (navOpen ? " nav-open" : "")}>
        <div className="app-nav-holder">
          <ul className="app-sidebar-nav">
            <li>
              <NavLink to="/dashboard">
                <img src="/home.svg" alt="icon" />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/building-management">
                <img src="/trade.svg" alt="icon" />
                <span>Building Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/customer-management">
                <img src="/team.svg" alt="icon" />
                <span>Customer Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/scheduler-management">
                <img src="/calendar.svg" alt="icon" />
                <span>Scheduler Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/technician-management">
                <img src="/technician.svg" alt="icon" />
                <span>Technician Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/pin-management">
                <img src="/pin-manage.svg" alt="icon" />
                <span>Pin Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin-follow-up-requests">
                <img src="/follow_up_request.svg" alt="icon" />
                <span>Follow Up Requests</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin-draft-requests">
                <img src="/follow_up_request.svg" alt="icon" />
                <span>Draft Requests</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/invalid-comments-missing-units">
                <img src="/sync-sidebar.svg" alt="Resync / Missing Units" />
                <span>Resync / Missing Units</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/price-management">
                <img src="/price-managment.svg" alt="Price Management" />
                <span>Price Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/portal-documents">
                <img src="/folder.svg" alt="folder" />
                <span>Portal Documents</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings">
                <img src="/settings.svg" alt="settings" />
                <span>Settings</span>
              </NavLink>
            </li>
          </ul>
        </div>

        <ul className="app-sidebar-nav app-sidebar-bottom-link">
          <li>
            <div
              className="app-sidebar-nav-item"
              onClick={() => onOpen_mdl_updatePassword()}
            >
              <img src="/icon-padlock.svg" alt="" />
              <span>Update Password</span>
            </div>
          </li>
          <li>
            <div
              className="app-sidebar-nav-item"
              onClick={() => logout_onClick()}
            >
              <img src="/icon-logout.svg" alt="" />
              <span>Logout</span>
            </div>
          </li>
        </ul>
      </aside>

      {/***** MODAL: CREATE CUSTOMER **************************************************************************/}
      <Modal
        id="mdl-update-password"
        dimmer={"inverted"}
        open={mdl_updatepassword_open}
        onClose={() => set_updatepassword_open(false)}
      >
        <MDL_UPDATE_PASSWORD
          set_mdl_open={set_updatepassword_open}
        ></MDL_UPDATE_PASSWORD>
      </Modal>

      {/***** END MODAL: CREATE CUSTOMER **************************************************************************/}
    </>
  );
}

export default Sidebar;
