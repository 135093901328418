import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal, Popup, Progress, Radio, Tab, TabPane } from "semantic-ui-react";
import { AppContext } from "../../../../Context/AppContext";
import { useHistory, useLocation,useSearchParams } from "react-router-dom";
import {
  downloadHeatmapAPI,
  getBuildingHeatmapAPI,
  getSingleBuilding,
  getAllPests,
  getBuildingInfoForBuildingHeatmapReport
} from "../../../../constants";
// import OutlinedInput from '@mui/material/OutlinedInput';
//import PDFViewer from './pdfViewer/PDFViewer';
import { Dropdown } from "semantic-ui-react";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import MDL_UNIT_HISTORY from "../../../scheduler/BuildingHeatmap/Components/mdl_unit_history/mdl_unit_history";
import moment from "moment";
import "../Home.css";
import { uniqueId  } from "lodash";
import BuildingOrders from "../../BuildingOrders";

export default function Buildingheatmapreport(){
console.log('report rendered!')
    const {
        bInfo,
        cartItems,
        selectedBuilding,
        buildingUnits,
        sbuildingData,
        userInfo,
        selected_units,
        sliderCart,
        checkedUnitsData,
        UncheckedUnitsData,
        orderPriceSummary,
        BuildingUpdate,
      } = useContext(AppContext);
      const location = useLocation();
      const [orderSummary,setorderSummary] = orderPriceSummary;
      const [sideSliderCart, setSideSliderCart] = sliderCart;
      const [cart_items, setCartItems] = cartItems;
      const [, set_add_unit] = selected_units;
      const [, setBuildingUnits] = buildingUnits;
      const [, setUser] = userInfo;
      const [selected_building, setSelectedBuilding] = selectedBuilding;
      const [building_heatmap, setBuildingHeatmap] = useState({});
      const [var_mdl_unit_history, set_mdl_unit_history] = useState(null);
      const [pestTreated, setPestTreated] = useState();
      const [pest_value,set_pest_value]=useState('all');
      const [page_visible,set_page_visible]=useState(false);
      const [filter] = useState([
        {
          value: 3,
          text: "3 Months",
        },
        {
          value: 6,
          text: "6 Months",
        },
        {
          value: 9,
          text: "9 Months",
        },
        {
          value: 12,
          text: "12 Months",
        },
      ]);
      const [selected_unit, set_selected_unit] = useState(null);
      const [selected_filter, setSelectedFilter] = useState(3);
      const [, setLegends] = useState({});
      const [building_info, setBuildingInfo] = bInfo;
      const [enable_selection, set_enable_selection] = useState(false);
      const history = useHistory();
      const [, setSingleBuildingData] = sbuildingData;
      const [var_first_loading,set_first_loading]=useState(true);
      const [FilterUpdated,setFilterUpdated]=useState(false);
      const [pestUpdated,setpestUpdated]=useState(false);
      const [buildingUpdate,setBuildingUpdate] = BuildingUpdate;
      const [checkedUnits, setCheckedUnits] = checkedUnitsData;
      const [UncheckedUnits, setUnCheckedUnits] = UncheckedUnitsData;
      const [var_mdl_progress, set_mdl_progress] = useState(false);
    
      /* EVENT LISTENERS
        ---------------------------------------------------------------------------------*/
      // useEffect(() => {
      //   prepareBuildingInfo();
      // }, [location.search]);
      const { search } = useLocation();
      const searchParams =new URLSearchParams(search);
      
      useEffect(() => {
        const select_id =  searchParams.get('s_id');
        const pestID =  searchParams.get('pestID');
        const months =  searchParams.get('months');
        // if (localStorage.getItem("s_id")) {
          
          console.log(select_id)
          if(pestID){
            set_pest_value(pestID);
            clearTimeout(getheatmap);
            console.log(pestID);
          }
          if(months){
            setSelectedFilter(months);
            clearTimeout(getheatmap);
            console.log(months)
          }
          if (select_id) {
            setSelectedBuilding(
              building_info?.filter((f_v) => f_v.value === select_id)[0]
            );
          }

        
          
      }, [building_info, location.search]);

      async function getBuildingInfo(){
        const select_id =  searchParams.get('s_id');
        await fetch(getBuildingInfoForBuildingHeatmapReport + select_id).then((res)=>res.json()).then((response)=>{console.log(response,'response Building info');
          if(response.code === 200){
            setBuildingInfo(response?.buildings)}
          })
      }
      useEffect(() => {
        
        getBuildingInfo()
      
        
      }, [])
      
    
      useEffect(() => {
        if (selected_building) {
          // const params = new URLSearchParams({
          //   "s_id": selected_building?.value,
          // });
          // history.replace({
          //   pathname: location.pathname,
          //   search: params.toString(),
          // });
          localStorage.setItem("s_id", selected_building?.value);
          getBuildingUnits(selected_building?.value);
        }
      }, [selected_building]);
    
      useEffect(() => {
        if (enable_selection) {
          setSideSliderCart(true);
        }
      }, [enable_selection]);
    
      useEffect(() => {
        if (UncheckedUnits && UncheckedUnits !== "All") {
          // Create a copy of checkedUnits object
          const updatedCheckedUnits = { ...checkedUnits };
          // Extract floor and unit from UncheckedUnits
          const [floor, unit] = UncheckedUnits.split("-");
          // Construct the key for the unit
          const unitKey = `${floor}-${unit}`;
          // Delete the unit from the object
          delete updatedCheckedUnits[unitKey];
          // Set the updated object to state
          //getBuildingHeatmap()
          setCheckedUnits(updatedCheckedUnits);
          setUnCheckedUnits(null);
        } else if (UncheckedUnits == "All") {
          setCheckedUnits({});
          setUnCheckedUnits(null);
          set_add_unit([{ unit_number: "", followup: "neworder", key: 0 }]);
        }
      }, [UncheckedUnits, checkedUnits]);
    
      /**
       * Heatmap Stuff
       */
      useEffect(() => {
        // Check both conditions in a single effect
        if (var_mdl_unit_history === false) {
          getBuildingHeatmap();
        }
      }, [var_mdl_unit_history]);
    
      useEffect(()=>{
        if(FilterUpdated){
          getBuildingHeatmap();
        }
      },[FilterUpdated])
    
      // useEffect(()=>{
      //   if(pest_value){
      //     getBuildingHeatmap();
      //   }
      // },[pest_value])
    
      useEffect(()=>{
        if(buildingUpdate){
          getBuildingHeatmap();
        }
      },[buildingUpdate])

      let getheatmap;
      useEffect(() => {
        // Check both conditions in a single effect
       
        if(pest_value && selected_filter && selected_building){
          getBuildingHeatmap();
          getPests();
        }
        return () => {
          clearTimeout(getheatmap)
          console.log('getheatmap cleared')
        }
      }, [pest_value,selected_filter,selected_building]);
    
    
    
    
      // useEffect(() => {
      //   if (selected_unit) {
      //     openModalUnitHistory();
      //   } else {
      //     closeModalUnitHistory();
      //   }
      // }, [selected_unit]);
    
      useEffect(() => {
        if (building_heatmap.length) getLegendValues();
      }, [building_heatmap]);
    
    
      useEffect(()=>{
          if(pestUpdated){
            getBuildingHeatmap();
          }
        },[pestUpdated])
    
      /* ASYNC FUNCTIONS
        ---------------------------------------------------------------------------------*/
    
      async function prepareBuildingInfo() {
        var buildings = [];
        const select_id = new URLSearchParams(location.search).get("s_id");
        if (localStorage.getItem("building")) {
          buildings = await JSON.parse(localStorage.getItem("building"));
          setBuildingInfo(buildings);
          if (!selected_building){
            setSelectedBuilding(buildings && buildings.length > 0 && buildings[0]);
          }else {
            const found_b = buildings.filter((val) => val.value == select_id);
            if (found_b.length) {
              setSelectedBuilding(found_b[0]);
            } else {
              setSelectedBuilding(
                buildings && buildings.length > 0 && buildings[0]
              );
            }
          }
        }
        if (localStorage.getItem("user")) {
          const user = await JSON.parse(localStorage.getItem("user"));
          setUser(user);
        }
      }
    
      function onChangeBuilding(event, { value }) {
        setSelectedBuilding(building_info.filter((f_v) => f_v.value === value)[0]);
        setBuildingUpdate(true);
      }
    
      async function getBuildingUnits(selected_id) {
        await fetch(
          `${getSingleBuilding}${selected_id || selected_building?.value}`
        )
          .then((response) => response.json())
          .then((response) => {
            const final_data = response.data[0];
            setSingleBuildingData(final_data);
            setBuildingUnits(final_data?.units ? final_data?.units : []);
          });
      }
    
      function handleOrderRequestClick() {
        setorderSummary([]);
        set_add_unit([{ unit_number: "", followup: "neworder", key: 0 }]);
        history.push(`/order-request/${selected_building?.location_id}`);
      }
    
      function handleBuildingHistoryClick() {
        set_add_unit([{ unit_number: "", followup: "neworder", key: 0 }]);
        history.push(`/building-history/${selected_building.value}`);
      }
    
      function handleBuildingOrderClick() {
        set_add_unit([{ unit_number: "", followup: "neworder", key: 0 }]);
        history.push(`/building-orders/${selected_building.value}`);
      }
    
      const handleCheckboxChange = (floor, unit) => {
        if (
          Object.keys(checkedUnits).filter((k) => k == `${floor}-${unit}`).length ==
          0
        ) {
          addDataIntoCart(floor, unit);
          setCheckedUnits((prev) => ({
            ...prev,
            [`${floor}-${unit}`]: true,
          }));
        } else {
          cart_items[floor].map((item, i) => {
            if (item.unit_number === unit) {
              handleDeleteItem(floor, i, unit);
            }
          });
        }
      };
    
      function handleModalUnitHistory(unit){
        set_selected_unit(unit);
        if (unit) {
          openModalUnitHistory();
        } else {
          closeModalUnitHistory();
        }
      }
    
      function openModalUnitHistory() {
        set_mdl_unit_history(true);
      }
    
      function closeModalUnitHistory() {
        set_mdl_unit_history(false);
      }
    
      function getIntegerFromString(val) {
        var re = /^(-?\d*)[^0-9]*(\d*)\.([\s\S]*?)$/;
        return parseInt(val?.replace(re, "$1$2"), 10);
      }
    
      function isAlphanumericWithSpace(inputString) {
        // Use a regular expression to check if the string is alphanumeric with space
        return /^[0-9]+$/.test(inputString) && /^[a-zA-Z]+$/.test(inputString);
      }
    
      async function getBuildingHeatmap() {
        set_mdl_progress(true);
        set_first_loading(false);
        
        let API_URL = `${getBuildingHeatmapAPI}${
          localStorage.getItem("s_id") || selected_building?.value
        }`;
      
        if (selected_filter) {
          API_URL += `?month=${selected_filter}`;
        }
    
        if(pest_value !== "all"){
          API_URL += `&pestID=${pest_value}`;
        }
    
        try {
          const response = await fetch(API_URL);
          const result = await response.json();
      
          if (result.data) {
            setBuildingHeatmap(result.data);
          } else {
            setBuildingHeatmap({});
          }
        } catch (error) {
          console.error("Error fetching heatmap data", error);
        } finally {
          set_mdl_progress(false);
          setFilterUpdated(false);
          setpestUpdated(false);
          setBuildingUpdate(false);
        }
      }
    
      function onChangeFilter(event, { value }) {
        if (value){
           setSelectedFilter(value);
           setFilterUpdated(true);
        }
      }
    
      function getActivityLevel(unit) {
        let className = "";
        if (unit) {
          if (unit.activity_level === "Low activity") {
            className = "light-activity";
          } else if (unit.activity_level === "Moderate activity") {
            className = "moderate-activity";
          } else if (unit.activity_level === "High activity") {
            className = "heavy-activity";
          } else if (unit.activity_level === "No activity") {
            className = "none-activity";
          } else if (
            !unit.activity_level &&
            !unit.prep_level &&
            !unit.sanitation_level &&
            !unit.last_invoice_id
          ) {
            className = "not-serviced";
          }
        } else {
          className = "";
        }
        return className;
      }
    
      function getLegendValues() {
        var no_activity = 0;
        var light_activity = 0;
        var medium_activity = 0;
        var high_activity = 0;
        var refused_entry = 0;
        var prepared = 0;
        var not_prepared = 0;
        var average_sanitation = 0;
        var low_sanitation = 0;
        var high_sanitation = 0;
        var structural_issues = 0;
    
        building_heatmap.forEach((data) => {
          no_activity += data.no_activity_count;
          light_activity += data.low_activity_count;
          medium_activity += data.medium_activity_count;
          high_activity += data.high_activity_count;
          refused_entry += data.refused_entry_count;
          prepared += data.prepared_count;
          not_prepared += data.not_prepared_count;
          average_sanitation += data.average_sanitation_count;
          low_sanitation += data.low_sanitation_count;
          high_sanitation += data.high_sanitation_count;
          structural_issues += data.high_sanitation_count;
        });
    
        setLegends({
          no_activity,
          light_activity,
          medium_activity,
          high_activity,
          refused_entry,
          average_sanitation,
          low_sanitation,
          high_sanitation,
          prepared,
          not_prepared,
        });
      }
    
      function getIconName(filtered_value) {
        let icon = "";
        if (filtered_value.refused_entry) {
          icon = <Icon color="red" name="minus circle"></Icon>;
        } 
         if (
          filtered_value.not_prepare ||
          filtered_value.prep_level === "Not Prepared"
        ) {
          icon = <Icon color="orange" name="close" key="close" />;
        }
        if (filtered_value.sanitation) {
          icon = <Icon name="paint brush"></Icon>;
        }
        return icon;
      }
    
      const handleDeleteItem = (itemKey, index, unit_number) => {
        // Make a copy of cart_items
        const updatedCartItems = { ...cart_items };
        let DeleteUnitData = itemKey + "-" + unit_number;
        // Remove the item at the specified index from the cart_items
        updatedCartItems[itemKey].splice(index, 1);
        // If the array becomes empty after deletion, remove it from cart_items
        setUnCheckedUnits(DeleteUnitData);
        if (updatedCartItems[itemKey].length === 0) {
          delete updatedCartItems[itemKey];
        }
        // Check if updatedCartItems is empty
        if (Object.keys(updatedCartItems).length == 0) {
          // Handle the case when cart_items becomes empty
          setCartItems(null);
        } else {
          // Update the state with the new cart_items
          setCartItems(updatedCartItems);
        }
      };
    
      /**
       *
       * @param {*} floor: will be the floor from which any item is clicked.
       * @param {*} unit_number:
       */
      function addDataIntoCart(floor, unit_number) {
        const appendedObject = {
          unit_number,
          followup: "neworder",
          floor,
          key: uniqueId(),
        };
    
        var cartData = cart_items ? { ...cart_items } : {};
        //If cartData is null then we have no need to compare the floor
        if (!cartData || !Object.keys(cartData).includes(floor)) {
          cartData = { ...cartData, [floor]: [appendedObject] };
        }
        //Now the turn is cartData have some data
        else if (
          cartData &&
          cartData[floor].filter((val) => val.unit_number === unit_number).length >
            0
        ) {
          // hand
          cartData[floor].map((item, i) => {
            if (item.unit_number === unit_number) {
              handleDeleteItem(floor, i, unit_number);
            }
          });
        } else {
          //If floor that is clicked exist in
          if (Object.keys(cartData).includes(floor)) {
            var floorData = cartData[floor];
            floorData = [...floorData, appendedObject];
            cartData = { ...cartData, [floor]: floorData };
          } else {
            cartData = { ...cartData, [floor]: [appendedObject] };
          }
        }
        setCartItems(cartData);
      }
    
      function getInstance() {
        if (!building_heatmap || !building_heatmap.floors) {
          return <></>;
        }
        const floors = Object.keys(building_heatmap.floors);
        return floors.map((floor, i) => {
          var floorsChildren = building_heatmap.floors[floor];
          if (floorsChildren.length > 0) {
            return (
              <div
                className="flex row-wrapper"
                style={{ alignItems: "center", cursor: "pointer" }}
                key={i}
              >
			  
                <span style={{minWidth:'130px',display:'flex',alignItems:'center',paddingLeft:'28px',justifyContent:'flex-start'}} className="text-center first-column-spacing-report text-center">
                  {floor}
                </span>
				<div style={{display:'flex',flexWrap:'wrap',}}>
                {(() => {
                  const jsxArray = [];
                  let index = 0;
                  var previousNumber = null;
                  var tempVals = [];
                  for (var k = 0; k < floorsChildren.length; k++) {
                    if (isAlphanumericWithSpace(floorsChildren[k]?.unit)) {
                      tempVals.push(floorsChildren[k]);
                    } else {
                      var currentNumber = getIntegerFromString(
                        floorsChildren[k]?.unit
                      );
                      if (previousNumber !== null && currentNumber !== null) {
                        while (++previousNumber < currentNumber) {
                          tempVals.push(null);
                        }
                      }
                      tempVals.push(floorsChildren[k]);
                      previousNumber = currentNumber;
                    }
                  }
                  floorsChildren = tempVals;
                  do {
                    const child = floorsChildren[index];
                    const inputId = `checkbox-${floor}-${index}`;
                    const unitKey = `${floor}-${child?.unit}`;
                    if (child) {
                    jsxArray.push(
                      
                      <div
                        className={`column-border-report ${
                          i === 0 ? "borderTop" : ""
                        } ${getActivityLevel(child)}` + ` ${checkedUnits[unitKey] ? 'secondary-border': ''}`}
						style={{pageBreakInside:'avoid'}}
                        key={index}
                        
                      >
                       <div
                            className="flex flex-column flex-center icon-container-size"
                            style={{ cursor: "pointer" }}
                          >
                        
                            <span className="text-center text-wrap">
                              {/* {child && getIconName(child)}
                              {child?.lastOrderStatus === "in progress" && (
                                <Icon color="grey" name="bell" />
                              )}
                              {child?.lastOrderStatus === "scheduled" && (
                                <Icon color="grey" name="bell" />
                              )}
                              {child?.structural_issues && " 🛠️"} */}
                              {child && (
                                <>
                                {/* {child.lastOrderStatus === "in progress" ? (
                                  <Icon color="grey" name="bell" />
                                ) : null}
                          
                                {child.lastOrderStatus === "scheduled" ? (
                                  <Icon color="grey" name="bell" />
                                ) : null} */}
                          
                                {child.structural_issues ? (
                                  <span><Icon name="wrench"></Icon></span>
                                ) : null}
                          
                                {child.refused_entry ? (
                                  <Icon color="red" name="minus circle" />
                                ) : null}
                          
                                {(child.not_prepare || child.prep_level === "Not Prepared") ? (
                                  <Icon color="orange" name="close" key="close" />
                                ) : null}
                          
                                {child.sanitation ? (
                                  <Icon name="paint brush" />
                                ) : null}
                              </>
                              )}
                            </span>
    
                            <span className="text-center text-wrap">
                              <a onClick={(e) => {
                                e.preventDefault();
                                e.target.style.color = '#681DA8'
                                if (enable_selection) {
                                  handleCheckboxChange(floor, child.unit, true);
                                } else {
                                  handleModalUnitHistory(child.unit);
                                }
                                }} style={{color: '#4C4C4C'}}>{child?.unit}
                              </a>
                            </span>
                            <span className="icon-add">
                              <input
                              type="checkbox"
                              id={inputId}
                              checked={checkedUnits[unitKey] || false}
                              style={{ display: "none" }}
                            />
                            { checkedUnits[unitKey] 
                            ? <Button 
                            color="green" 
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the parent onClick from being triggered
                              handleCheckboxChange(floor, child.unit, !checkedUnits[unitKey]);
                                setSideSliderCart(true);
                            }}
                            style={{
                              borderRadius: '24px',
                              width: '30%',
                              padding: '7px 8px',
                              display: 'flex',            // Flexbox display
                              alignItems: 'center',       // Vertically center content
                              justifyContent: 'center',
                              position: 'absolute',
                              bottom:'2px',
                              right:'2px'    
                            }}
                            title="Remove from cart"
                          >
                            <Icon name="check" style={{ alignSelf: 'center',margin:'0px',fontSize: '15px', }} />
                          </Button>
                            
                            : <Button color="blue" type='button' onClick={(e) => {
                                e.stopPropagation(); // Prevent the parent onClick from being triggered
                                handleCheckboxChange(floor, child.unit, !checkedUnits[unitKey]);
                                setSideSliderCart(true);
                              }} style={{borderRadius:'20px',
                                width: '30%',
                                padding: '0px',
                                fontSize: '28px',
                                position: 'absolute',
                                bottom:'2px',
                                right:'2px',
                                fontWeight: '600'}}
                                title="Add to cart"
                                >+</Button>}
                            </span>
                          </div>
                      </div>
                    );
                  }
                    index++;
                  } while (index < floorsChildren.length);
    
                  return jsxArray;
                })()}
              </div></div>
            );
          }
    
          return null;
        })
      }
    
      async function downloadReport() {
        var pestFilter = "";
        if(pest_value !== "all"){
          pestFilter = `&pestID=${pest_value}`;
        }
        const url = `${
          
             +
          (localStorage.getItem("s_id") || selected_building?.value)
        }?month=${selected_filter}${pestFilter}&download=true`;
        try {
          window.open(url, "_blank");
        } catch (error) {
          console.error("Error:", error);
        }
      }
    
      const OnChangePest = (e, { value }) => {
        console.log(value);
        set_pest_value(value);
        setpestUpdated(true);
      }
    
      async function getPests() {
        await fetch(getAllPests)
          .then((response) => response.json())
          .then((res) => {
            let allTempPest = [];
            if (res.code === 200) {
              allTempPest = res.data.map((value) => ({
                key: value.pestName,
                text: value.pestName,
                value: value.pestId,
              }));
      
              // Adding the "All" object at the beginning of the array
              allTempPest.unshift({
                key: 'all',
                text: 'All',
                value: 'all',
              });
      
              setPestTreated(allTempPest);
            }
          });
      }
    

    return(<>
    <div style={{backgroundColor:'#ffffff'}}>
    <div className="app-content-action app-content-action-search" style={{marginBottom:'0'}}>
      <div className='pageLoader' style={{hidden:page_visible ?'true':'false'}}></div>
      <div className="app-content-action-right" >
      </div>
    </div>
    <div className="app-card" style={{backgroundColor:'#ffffff!important',padding:'0 20px 10px 20px',border:'none',marginBottom:0,borderRadius:0}}>
      <div className="app-card-header" style={{alignItems:'baseline',flexWrap:'wrap',marginBottom:0}}>
        </div>

          {/* <Grid.Column
            style={{ margin: "" }}
            computer={12}
            tablet={16}
            className=""
          > */}
          {building_heatmap?.legend && 
          <div style={{width: "100%",marginLeft:'4px'}}>
            <span className="app-card-title">
              Statistics
            </span>
                <div style={{display:'flex',flexWrap:'wrap',gap:'16px',marginTop:'16px'}}>
                                <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}>No Activity</span><span>{building_heatmap.legend["None_Activity"]?.percentage+'%'}</span></div>
                                      <Progress percent={building_heatmap.legend["None_Activity"]?.percentage} style={{background:'#E5E5E5'}} size="tiny" inverted className='noneactivity'/>
                                  </div>
                                  <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}>Light Activity</span><span>{building_heatmap.legend["Light_Activity"]?.percentage+'%'}</span>
                                  </div>
                                      <Progress percent={building_heatmap.legend["Light_Activity"]?.percentage} className="lightactivity" size="tiny" />
                                  </div>
                                  <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}>Moderate Activity</span><span>{building_heatmap.legend["Moderate_Activity"]?.percentage+'%'}</span></div>
                                      <Progress percent={building_heatmap.legend["Moderate_Activity"]?.percentage} className="moderateactivity" size="tiny" color="orange"/>
                                  </div>
                                  <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}>Heavy Activity</span><span>{building_heatmap.legend["Heavy_Activity"]?.percentage +'%'}</span></div>
                                      <Progress percent={building_heatmap.legend["Heavy_Activity"]?.percentage} className="heavyactivity" size="tiny" color="yellow"/>
                                  </div>
                                  <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}>Not Serviced</span><span>{building_heatmap.legend["Not_Serviced"]?.percentage+'%'}</span></div>
                                      <Progress percent={building_heatmap.legend["Not_Serviced"]?.percentage} className="notserviced" size="tiny" color="grey"/>
                                  </div>
                                 
                                  <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <Popup content={'Refused Entry / Not Prepared'} trigger={<span style={{fontWeight:'bold',textOverflow:'ellipsis',overflow: 'hidden',whiteSpace:'nowrap'}}><Icon color="red" name="close"></Icon> / <Icon color="red" name="minus circle"></Icon> Refused Entry/Not Prepared</span> }/>
                                    <span>{Number(building_heatmap.legend["Not_Prepared"]?.percentage)+Number(building_heatmap.legend["Refused_Entry"]?.percentage)+'%'}</span></div>
                                      <Progress percent={Number(building_heatmap.legend["Not_Prepared"]?.percentage)+Number(building_heatmap.legend["Refused_Entry"]?.percentage)} className="notprepared" size="tiny" />
                                  </div>
                                 
                                  {/* <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold'}}><span>{building_heatmap.legend["Refused_Entry"]?.percentage+"%"}</span></div>
                                      <Progress percent={building_heatmap.legend["Refused_Entry"]?.percentage} size="tiny" color="red"/>
                                  </div> */}
                                   <div className="stats">
                                  <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}>
                                    <span style={{fontWeight:'bold'}}><Icon name="paint brush"></Icon> Sanitation </span><span>{building_heatmap.legend["Sanitation"]?.percentage+"%"}</span></div>
                                      <Progress percent={building_heatmap.legend["Sanitation"]?.percentage} size="tiny" color="purple"/>
                                  </div>
                                  <div className="stats">
                                   <div style={{display:'flex',justifyContent:'space-between',marginBottom:'8px'}}> <Popup content={'Structural'} trigger={<span style={{fontWeight:'bold'}}><Icon name="wrench"></Icon> Structural</span>}/><span>{Number(building_heatmap?.legend["Structural_Issues"]?.percentage)+'%'}</span></div>
                                      <Progress percent={Number(building_heatmap.legend["Structural_Issues"]?.percentage)}  size="tiny" className="structural" color="teal"/>
                                  </div>
                                </div>
                          </div>}
            
           
          {/* </Grid.Column> */}
      </div>
      <div className="heatmap-container" style={{backgroundColor:'#ffffff'}}>
        <div className="" style={{ margin: "0",width:"100%" }}>
          {getInstance()}
        </div>
      </div>
	  <div syle="height:90vh;width:100%;background:white;-webkit-print-color-adjust:exact"></div>
      </div>
    {/* </div> */}
    </>)
}