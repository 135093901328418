//const BASEURL = "https://api.portal.homepropestcontrol.ca/"; // VM server base url
// const BASEURL="http://homepro-dev.powersoft19.com:5000/";// VM server base url
// const BASEURL = "http://homepro.ps19testserver.com:5000/"; //AWS server url
const BASEURL = "https://homepro-test.ps19testserver.com/api/"; //AZURE server url
// const BASEURL = "https://stg-api.portal.homepropestcontrol.ca/"; // staging server

// const BASEURL = "http://localhost:5000/"; // Localhost base url

export const getAllUsers = BASEURL + "users";
export const getSpecificUSer = BASEURL + "users/";
export const updateUser = BASEURL + "users/update/";
export const createUser = BASEURL + "users/register";
export const loginUser = BASEURL + "users/login";
export const deleteUser = BASEURL + "users/delete";
export const registerBuilding = BASEURL + "buildings/register";
export const resetPin = BASEURL + "reset-pin";
export const addBulkUnit = BASEURL + "unit/bulk-add-units/";
export const updateUnits = BASEURL + "unit/update-unit/";
export const addSingleUnit = BASEURL + "unit/add-unit";
export const deleteUnit = BASEURL + "unit/delete-unit/";
export const deleteAllUnit = BASEURL + "unit/delete-unit";
export const getAllBuildings = BASEURL + "buildings";
export const deleteBuilding = BASEURL + "buildings/delete";
export const getSingleBuilding = BASEURL + "buildings/get-building/";
export const getSingleBuildingLookup = BASEURL + "buildings/lookup";
export const postBulkBuildings = BASEURL + "buildings/bulk-building-add/";
export const updateBuilding = BASEURL + "buildings/update/";
export const loginBuilding = BASEURL + "buildings/login-building";
export const loginMasterPinBuildings = BASEURL + "users/customer-login";
export const updatePassword = BASEURL + "users/update/password";
export const getAllRestPinRequests = BASEURL + "reset-pin/get-all-pin-request/";
export const changeResetStatus = BASEURL + "reset-pin/reset-pin-status/";
export const getAdminStat = BASEURL + "stats/admin-stats";
export const getRecentOrder = BASEURL + "service-orders/recent/order";
export const getRecentCustomers = BASEURL + "users/recent/customers";
export const buildingStat = BASEURL + "stats/building-stats/";
export const postOrder = BASEURL + "service-orders/add-order";
export const deleteOrder = BASEURL + "service-orders/delete-customer-order";
export const getAllPests = BASEURL + "pests";
export const getBuildingInfoForBuildingHeatmapReport = BASEURL + "buildings/get-building-lookup/get-building-for-heatmap/";
export const getAllPestsTreatment = BASEURL + "treatment-option/";
export const getAllOrdersOfUser = BASEURL + "service-orders/customer-order/";
export const getAllOrdersOfScheduler =
  BASEURL + "service-orders/scheduler-order/";
export const orderDetailsGet = BASEURL + "service-orders/get/order-details/";
export const getBuildingRequetsOrder =
  BASEURL + "service-orders/get/building-requested-order";
export const getTechOrders =
  BASEURL + "service-orders/get/tech-requested-order?tech_id=";
export const resendOrderTechnician =
  BASEURL + "service-orders/resend-follow-order";
export const viewTechDetailsGet =
  BASEURL + "service-orders/view-followup-details?id=";
export const getAdminFollowUpRequests =
  BASEURL + "service-orders/get-followup-request";
export const getAdminDraftRequests =
  BASEURL + "service-orders/get-draft-request";
export const acceptOrRejectOrder =
  BASEURL + "service-orders/add-requested-order";
export const updateOrAddOrderDetails =
  BASEURL + "service-orders/add-scheduler-order";
export const getOrderTimeRange =
  BASEURL + "service-orders/get-scheduler-time-range";
export const postUpdateTimeWindow =
  BASEURL + "service-orders/post-scheduling-orders";
export const deleteSchedulerOrder =
  BASEURL + "service-orders/delete-scheduler-order?service_id=";
export const viewPdfURL =
  BASEURL + "service-orders/get-inspection-report?order_number=";
export const searchBuilding = BASEURL + "buildings/get-scheduler-building?q=";
export const submitChangeStatus =
  BASEURL + "service-orders/update-order-status";
export const deleteDraftOrderUnits =
  BASEURL + "service-orders/delete-customer-draft-order?order_unit_id=";
export const getSettingsCustomersList = BASEURL + "customers";

export const getBuildingSummaryAPI = BASEURL + "buildings/summary/";
export const downloadSummaryAPI = BASEURL + "buildings/view-summary/";
export const downloadHeatmapAPI = BASEURL + "buildings/view-heat-map/";
export const getUnitSummaryAPI = BASEURL + "buildings/unit-history/";
export const getBuildingHeatmapAPI = BASEURL + "buildings/heat-map/";
export const portalDocuments = BASEURL + "portal-documents";
export const portalReadPdf = BASEURL + "service-orders/read-pdf";
export const resendConfirmationEmail = BASEURL + "service-orders/post-confirmation-email/";
export const getInvalidCommentsAPI = BASEURL + "service-orders/get-invalid-comments";
export const resyncInvalidCommentsAPI = BASEURL + "service-orders/resync-invoices-comments";
export const missingUnitsAPI = BASEURL + "buildings/invalid-units-report";
export const getPestLookup = BASEURL + "pests/pest/lookup";
export const getTreatmentLookup = BASEURL + "treatment-option/treatment/lookup";
export const getBuildingLookup = BASEURL + "buildings/building-look-up";
export const postWarranty = BASEURL + "warranty/add-warranty";
export const putWarranty = BASEURL + "warranty/put-warranty/";
export const getWarrantyData = BASEURL + "warranty/";
export const deleteWarranty = BASEURL + "warranty/delete-warranty/";
export const deleteAllWarranty = BASEURL + "warranty/delete-all-location-warranty/";
export const postBulkUploadBuildings = BASEURL + "buildings/bulk-upload";
export const getUnitServiceDetails = BASEURL + "buildings/unit-service-details/";
export const getListofScheduler = BASEURL + "users/scheduler/lookup";
export const getListofPestAbb = BASEURL + "pests/pest/abbreviation-lookup";
export const getTreatmentDetailLookup = BASEURL + "treatment-details/treatment-details-lookup";
export const createCriticalUnit = BASEURL + "critical-units/add-critical-unit";
export const CriticalListData = BASEURL + "critical-units";
export const UpdateCriticalUnitData = BASEURL + "critical-units/update-critical-unit/";
export const deleteCriticalUnit = BASEURL + "critical-units/delete-critical-unit/";
export const getCriticalUnitServiceDetails = BASEURL + "critical-units/get/unit-service-details/";
export const getBuildingNotes = BASEURL + "building-notes/";
export const getNote = BASEURL + "building-notes/get/note-details/";
export const createBuildingNotes = BASEURL + "building-notes/add-note";
export const updateBuildingNotes = BASEURL + "building-notes/update-note/";
export const deleteBuildingNotes = BASEURL + "building-notes/delete-note/";
export const treatmentPrice = BASEURL + "order-summary/get-service-price";
export const allBranchPrice = BASEURL + "order-summary/get-all-branch-code";
export const syncBranchPrice = BASEURL + "order-summary/sync-branch-price/";
export const downloadPestTracker = BASEURL + "service-orders/download/pest-control-tracker/";