import React, { useEffect, useState } from 'react';
import { Table,Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import Statistics from '../../../components/cmp-statistics/Statistics';
import { getAdminStat, getRecentCustomers, getRecentOrder } from '../../../constants';
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import './Dashboard.css'



function Dashboard() {
    const [ statistics, setStatistics ] = useState();
    const [recent_order, setRecentOrder] = useState([]);
    const [recent_users, setRecentUsers] = useState([]);
    const [var_mdl_progress, set_mdl_progress] = useState(false);
    const routeHistory = useHistory();



    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

    useEffect(()=>{
        getOrdersRecent();
    },[])

    useEffect(()=>{
        getUserRecent();
    },[])

    useEffect(()=>{
        getState();
    },[])

    async function getOrdersRecent(){
        set_mdl_progress(true);
        await fetch(getRecentOrder)
        .then(response=>response.json())
        .then(res=>{
            if(res.statusCode===200){
                setRecentOrder(res.body.data);
            }
            else{
                setRecentOrder([])
            }
        })
        .catch(error=>{
            //setStatistics(null)
            console.error("Error:",error);
        })
        set_mdl_progress(false);
    }

    const clickEventStat=(type)=>{
        if(type==="technician")
        routeHistory.push("/technician-management")
        else if(type==="schedulers")
        routeHistory.push("/scheduler-management")
        else if(type==="buildings")
        routeHistory.push("/building-management")
    }

    async function getUserRecent(){
        await fetch(getRecentCustomers)
        .then(response=>response.json())
        .then(res=>{
            if(res.statusCode===200){
                setRecentUsers(res.body.data);
            }
            else{
                setRecentUsers([])
            }
        })
        .catch(error=>{
            //setStatistics(null) 
            console.error("Error:",error);
            setRecentUsers([])
        })
    }
    
    async function getState(){
        await fetch(getAdminStat)
        .then(response=>response.json())
        .then(res=>{
            if(res.code===200){
                setStatistics(res);
            }
            else{
                setStatistics(null)
            }
        })
        .catch(error=>{
            setStatistics(null)
            console.error("Error stat:",error);
        })
    }

    return(
        <>
        <div className="app-content">
            <div className="dashboard-statistics">
				<div className="stats-col">
					<div className="app-card progress-orange">
						<Statistics
							title="Technicians"
							count={statistics? statistics.total_customers:0}
							icon="/customers.svg"
                            clickEvent={()=>clickEventStat("technician")}
                            progress={statistics?statistics.customer_percentage:0}
                            prevCount={statistics && statistics.total_previous_month_customer?statistics.total_previous_month_customer:0}
                            thisMonth={statistics && statistics.total_current_month_customer?statistics.total_current_month_customer:0}
						/>
					</div>
				</div>
				<div className="stats-col">
					<div className="app-card progress-blue">
						<Statistics
							title="Schedulers"
							count={statistics? statistics.total_schedulers:0}
							icon="/schedulers.svg"
                            clickEvent={()=>clickEventStat("schedulers")}
                            progress={statistics?statistics.scheduler_percentage:0}
                            prevCount={statistics && statistics.total_previous_month_scheduler?statistics.total_previous_month_scheduler:0}
                            thisMonth={statistics && statistics.total_current_month_scheduler?statistics.total_current_month_scheduler:0}
						/>
					</div>
				</div>
				<div className="stats-col">
					<div className="app-card progress-green">
						<Statistics
							title="Buildings"
							count={statistics? statistics.total_buildings:0}
							icon="/buildings.svg"
                            clickEvent={()=>clickEventStat("buildings")}
                            progress={statistics?statistics.building_percentage:0}
                            prevCount={statistics && statistics.total_previous_month_building?statistics.total_previous_month_building:0}
                            thisMonth={statistics && statistics.total_current_month_building?statistics.total_current_month_building:0}
						/>
					</div>
				</div>
            </div>

            <div className="dashboard-card-row">

                <div className="latest-customers-container">
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">Latest Joined Technician(s)</div>
                        </div>
                        <div className="app-card-body">
                            <div className="app-latest-customers-list">
                                {recent_users && recent_users.length > 0 ? 
                                    recent_users.map((value,index)=>(
                                        <div key={index} className="app-latest-customer">
                                            <div className="latest-customer-short-title">
                                                {value.first_name&& value.first_name[0].toUpperCase()}{value.last_name && value.last_name[0].toUpperCase()}
                                            </div>
                                            <div className="latest-customer-detail">
                                                <div className="latest-customer-title">
                                                    {value.first_name} {value.last_name}
                                                </div>
                                                <div className="latest-customer-email">
                                                    {value.email}
                                                </div>
                                            </div>
                                        </div>

                                    )):
                                    <div className="nodata-placeholder">
                                        There is no recent joined technicians to display.
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="latest-order-request-contaienr">
                    <div className="app-card">
                        <div className="app-card-header">
                            <div className="app-card-title">Latest Order Request(s)</div>
                        </div>
                        <div className="app-table-wrapper">
                            <Table unstackable>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Customer</Table.HeaderCell>
                                        <Table.HeaderCell>Scheduler</Table.HeaderCell>
                                        <Table.HeaderCell>Building</Table.HeaderCell>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {!var_mdl_progress && recent_order && recent_order.length>0 &&
                                        recent_order.map((value,index)=>(
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <div className="td-customer-name">
                                                        <div className="td-short-name">{value.customer_name[0].toUpperCase()}</div>
                                                        <div className="td-full-name">{value.customer_name} </div>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div className="td-customer-name">
                                                        <div className="td-short-name">{value.scheduler_name[0].toUpperCase()}</div>
                                                        <div className="td-full-name">{value.scheduler_name} </div>
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell>{value.building_name}</Table.Cell>
                                                <Table.Cell className="td-status" collapsing>
                                                {value.order_status === 'completed' &&
                                                <div style={{color: '#05881F'}}>Complete</div>
                                                }
                                                {value.order_status === 'scheduled' &&
                                                    <div style={{color: '#05881F'}}>Scheduled</div>
                                                }
                                                {value.order_status === 'in progress' &&
                                                    <div style={{color: '#FA8C16'}}>In Progess</div>
                                                }
                                                </Table.Cell>
                                            </Table.Row>
                                        )) 
                                    }
                                </Table.Body>

                            </Table>
                            {!var_mdl_progress && recent_order.length === 0 &&
                                <div className="nodata-placeholder">
                                    There is no recent orders to display
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
        </>
    )
}

export default Dashboard;

