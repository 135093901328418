import React, { useState, useEffect,useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Form,
  Button,
  Modal,
  Icon,
  Input,
  Radio,
} from "semantic-ui-react";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../../../Context/AppContext";
import {
  updateBuilding
} from "../../../../constants";

export default function MDL_UPDATE_BUILDING({set_mdl_open,var_building_order,set_building_order,var_add_building,set_var_add_building,schedulers}) {
    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo,validateTextField } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const location = useLocation();
  const { addToast } = useToasts();
  const [isUpdate, setIsUpdate] = useState(false);
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [isError, setISError] = useState();
  const [errors, set_errors] = useState({});
  const [var_disabled, set_disabled] = useState(false);
  const [success_message, setSuccessMessage] = useState();

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/

  useEffect(() => {
    var_building_order.value  = var_add_building.building_po_mo_wo;
  }, [var_add_building]);

  const onChangeBuildingOrder = (e, { value }) => {
    set_building_order({ value });
    var_add_building.building_po_mo_wo = value;
  };

  const onChangeScheduler = (e, { value }) => {
    var temp = { ...var_add_building };
    temp.scheduler_id = value;
    set_var_add_building(temp);
    setISError(false);
  };

  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setISError(false);
    set_errors({ ...errors, [name]: null });
    var temp_error = { ...errors };
   
    if (name === "email" || name === "customer_email") {
        var pattern_email = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern_email.test(value)) {
            temp_error[name] = "Please enter a valid email address.";
        } else {
            temp_error[name] = null;
        }
    }

    if (name === "master_pin" || name === "building_pin") {
        var pattern_number = /^[0-9]*$/;
        if (!pattern_number.test(value)) {
          temp_error[name] = "Please enter only numbers.";
        } else {
          temp_error[name] = null;
        }
    }

    if (name === "customer_name") {
      if (validateTextField(value) === false) {
        temp_error[name] = "Please enter valid name.";
      } else {
        temp_error[name] = null;
      }
    }

    if (name === "location_id") {
      if (value.length === 1  || value.length === 0) {
        temp_error[name] = "Please enter valid location id.";
      } else {
        temp_error[name] = null;
      }
    }
    if(temp_error[name] === null) {
      set_disabled(false);
    }else{
      set_disabled(true);
    }
    set_errors(temp_error);
    set_var_add_building({ ...var_add_building, [name]: value });
};


  function isValidated() {
    var temp_error = { ...errors };
    var isValid = true;

    if (!var_add_building || !var_add_building.hasOwnProperty('building_name') || var_add_building.building_name === "") {
      temp_error.building_name = "Please add building name.";
      isValid = false;
    }
    if (!var_add_building || !var_add_building.hasOwnProperty('location_id') || var_add_building.location_id === "") {
      temp_error.location_id = "Please add building code.";
      isValid = false;
    }
    if (!var_add_building || !var_add_building.hasOwnProperty('TimeWindow1') || var_add_building.TimeWindow1 === "") {
      temp_error.TimeWindow1 = "Please add time window first.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('TimeWindow2') || var_add_building.TimeWindow2 === "") {
      temp_error.TimeWindow2 = "Please add Time window second.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('customer_name') || var_add_building.customer_name === "") {
      temp_error.customer_name = "Please add customer name.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('email') || var_add_building.email === "") {
      temp_error.email = "Please add customer email.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('master_pin') || var_add_building.master_pin === "") {
      temp_error.master_pin = "Please add master pin.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('building_pin') || var_add_building.building_pin === "") {
      temp_error.building_pin = "Please add pin.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('email') || var_add_building.email === "") {
      temp_error.email = "Please add building email.";
      isValid = false;
    }

    if (!var_add_building || !var_add_building.hasOwnProperty('scheduler_id') || var_add_building.scheduler_id === "") {
      temp_error.email = "Please add building email.";
      isValid = false;
    }
    if (isValid) {
      set_errors({});
    } else {
      set_errors(temp_error);
    }
    return isValid;
  }

  const update_building = async () => {
    if(isValidated()){
    set_mdl_progress(true);
    var_add_building.pin=var_add_building.building_pin;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify(var_add_building),
    };
    await fetch(updateBuilding + location?.pathname?.split('/')[2], requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          setUser(null);
        } else {
          if (res.code === 200) {
            setSuccessMessage("Building information updated successfully.");
            set_mdl_success(true);
            setIsUpdate(false);
            set_mdl_open(false);
            addToast(res.success, {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(res.failed, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        addToast("Some error while updating building. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
      set_mdl_progress(false);
    }
  };



  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <>
      <Form>
            <div className="modal-header">
                <div className="modal-header-title">Update Building</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
        <div className="modal-content">
        <Form>
        <div className="app-card">
          <div className="app-card-body">
            <Form.Group>
            <Form.Field
                label="Building Code"
                control={Input}
                placeholder="Write location code"
                name="location_id"
                value={var_add_building.location_id}
                onChange={onChangeInput}
                error={errors.location_id}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                label="Building Name"
                control={Input}
                name="building_name"
                //onChange={onChangeInput}
                value={var_add_building.building_name}
                placeholder="Write building name"
                error={errors.building_name}
                disabled={true}
              />
              <Form.Field
                label="Customer Name"
                control={Input}
                placeholder="Write customer name"
                value={var_add_building.customer_name}
                name="customer_name"
                onChange={onChangeInput}
                error={errors.customer_name}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                label="Address"
                control={Input}
                placeholder="Write address"
                name="building_address"
                value={var_add_building.building_address}
                onChange={onChangeInput}
                disabled={true}
              />
              <Form.Field
                label="City"
                control={Input}
                placeholder="Write city"
                name="building_city"
                value={var_add_building.building_city}
                onChange={onChangeInput}
                disabled={true}
              />
            </Form.Group>

            {/* <Form.Group>
              <Form.Field
                label="Time Window One"
                control={Input}
                name="TimeWindow1"
                onChange={onChangeInput}
                required
                value={var_add_building.TimeWindow1}
                placeholder="Write time eg. ( 8am - 12pm )"
                error={errors.TimeWindow1}
              />
              <Form.Field
                label="Time Window Second"
                control={Input}
                name="TimeWindow2"
                onChange={onChangeInput}
                required
                value={var_add_building.TimeWindow2}
                placeholder="Write time eg. ( 12pm - 5pm )"
                error={errors.TimeWindow2}
              />
            </Form.Group> */}

            <Form.Group>
              
              <Form.Field
                label="Customer Email"
                required
                control={Input}
                value={var_add_building.email ? var_add_building.email : ""}
                placeholder="Write customer email..."
                name="email"
                onChange={onChangeInput}
                error={errors.email}
              />
            </Form.Group>
            <Form.Group>
            <Form.Field
                label="Pin"
                // type={isPinVisible ? "text" : "password"}
                type={"text"}
                control={Input}
                value={var_add_building?.building_pin}
                placeholder="Write pin here"
                name="building_pin"
                onChange={onChangeInput}
                error={errors.building_pin}
                disabled={true}
                pattern="\d*"
                // icon={
                //   <Icon
                //     name={isPinVisible ? "eye slash" : "eye"}
                //     link
                //     onClick={togglePinVisibility}
                //   />
                // }
              />
              <Form.Field
                label="Master Pin"
                type={"text"}
                // type={isMasterPinVisible ? "text" : "password"}
                control={Input}
                value={var_add_building.master_pin}
                placeholder="Write master pin here"
                name="master_pin"
                autoComplete="new-password"
                onChange={onChangeInput}
                error={errors.master_pin}
                disabled={true}
                pattern="\d*"
                // icon={
                //     <Icon
                //       name={isMasterPinVisible ? 'eye slash' : 'eye'}
                //       link
                //       onClick={toggleMasterPinVisibility}
                //     />
                //   }
              />
              
            </Form.Group>
            <Form.Group>
              {/* <Form.Field
                label="Building Email"
                required
                control={Input}
                value={var_add_building.email}
                placeholder="Write building email (seprate by , )"
                name="email"
                onChange={onChangeInput}
                error={errors.email}
              /> */}
              {schedulers?.length &&
              <Form.Select
              label="Scheduler"
              placeholder="Select scheduler"
              fluid
              required
              value={var_add_building.scheduler_id}
              onChange={onChangeScheduler}
              name="scheduler_id"
              error={errors.scheduler_id}
              options={schedulers?.map(option => ({
                key: option.value,
                value: option.value,
                text: (
                  <div>
                    {option.text.split(' ')[0]}{' '}
                    <span style={{ color: 'lightgray' }}>
                      {option.text.split(' ')[1]}
                    </span>
                  </div>
                )
              }))}
            />
          }
            </Form.Group>
            <Form.Group>
              <Form.Field
                label="Tax %"
                className="pin-class"
                type={"text"}
                control={Input}
                value={var_add_building?.tax}
                name="building_pin"
                onChange={onChangeInput}
                error={errors.building_pin}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
            <Form.TextArea
                    id='note'
                    label='Location Notes'
                    style={{width:'100%',height: '60px'}}
                    name='note'
                    value={var_add_building?.note}
                    onChange={onChangeInput}
                    disabled={true}
                    error={errors["note"]}
                />
              {/* <Form.Field>
                <label>Building Order</label>
                <Radio
                  label="Purchase Order Number"
                  name="buildingOrder"
                  value="po"
                  checked={
                    var_building_order.value?.toString().toLowerCase() === "po"
                  }
                  onChange={onChangeBuildingOrder}
                />
                <Radio
                  label="Work Order Number"
                  name="buildingOrder"
                  value="wo"
                  checked={
                    var_building_order.value?.toString().toLowerCase() === "wo"
                  }
                  onChange={onChangeBuildingOrder}
                />
                <Radio
                  label="Maintenance Order Number"
                  name="buildingOrder"
                  value="mo"
                  checked={
                    var_building_order.value?.toString().toLowerCase() === "mo"
                  }
                  onChange={onChangeBuildingOrder}
                />
                <Radio
                  label="None"
                  name="buildingOrder"
                  value={null}
                  defaultChecked
                  checked={
                    !var_building_order.value ||
                    var_building_order.value === null ||
                    var_building_order.value === "null"
                  }
                  onChange={onChangeBuildingOrder}
                />
              </Form.Field> */}
            </Form.Group>
          </div>
        </div>
    </Form>
        </div>
        <div className="modal-footer">
            <Button onClick={() => set_mdl_open(false)} type="submit" color="red">
                Close
            </Button>
            <Button color="blue" onClick={update_building} disabled={var_disabled}>
              Update
            </Button>
        </div>
      </Form>


      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
}