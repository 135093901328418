import React, {useContext,useState,useEffect} from "react";
import { Table, Icon, Button, Label, Modal } from "semantic-ui-react";
import MDL_WARRANTY_DETAILS from "../mdl-warranty-detail/WarrantyDetails";
import MDL_UPDDATE_WARRANTY_DETAILS from "../mdl-update-warranty-detail/UpdateWarrantyDetails";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import MDL_CONFIRMATION from "../../../../components/cmp-confirmation/cmp-confirmation";
import moment from "moment";
import "./Warranty.css";
import { useToasts } from "react-toast-notifications";
import { getBuildingLookup,getWarrantyData,deleteAllWarranty } from "../../../../constants";

export default function MDL_WARRANTY({ set_mdl_open,building_id,var_add_building }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  
  const [buildingUnitInfo, setBuildingUnitInfo] = useState([]);
  const [var_mdl_warranty_detail_open, set_mdl_warranty_detail_open] = useState(false);
  const [WarrantyData, setWarrantyData] = useState([]);
  const { addToast } = useToasts();
  const [WarrantyItemData, setWarrantyItemData] = useState([]);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [var_mdl_warranty_update_open, set_mdl_warranty_update_open] = useState(false);
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    fetchBuildingInfo()
    fecthWarrantyData()
}, [var_mdl_warranty_detail_open,var_mdl_warranty_update_open])
  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  // Function to fetch building info based on search query
  async function fetchBuildingInfo() {
    try {
      const response = await fetch(getBuildingLookup + `?q=${var_add_building?.building_name}`);
      if (!response.ok) {
        throw new Error("Failed to fetch building info");
      }
      const data = await response.json();
      if (data.code == 200) {
        setBuildingUnitInfo(data?.data[0]?.units);
      } else {
        console.log("Else data:::", data.message);
      }
    } catch (error) {
      console.error("Error fetching building info:", error);
    }
  }

  async function fecthWarrantyData(){
    try {
      set_mdl_progress(true);
      const response = await fetch(getWarrantyData+`${building_id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch Warranty info");
      }
      const data = await response.json();
      if (data.statusCode == 200) {

        setWarrantyData(data?.body?.data);
      } else {
        console.log("Else data:::", data.message);
      }
    } catch (error) {
      console.error("Error fetching building info:", error);
    }
    set_mdl_progress(false);
  }

  function goToUpdateWarranty(WarrantySingleData){
    setWarrantyItemData(WarrantySingleData);
    set_mdl_warranty_update_open(true);
  }

  async function delete_all_warranty() {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    var apiUrl = deleteAllWarranty+`${building_id}`;
    await fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        const { success, code } = res;
        if (code === 200) {
          addToast(success, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(success, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      fecthWarrantyData()
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <div>
        <div className="modal-header">
          <div className="modal-header-title">Warranty</div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
        <Button type="submit" color="red" onClick={()=> set_mdl_warranty_detail_open(true)}>
            Add Warranty
          </Button>
          <Button type="submit" color="red" onClick={()=> set_mdl_confirmation(true)}>
            Delete All Warranty
          </Button>
          <div className="modal-warranty-info">
            {/* <div className="app-card-title">
              <span style={{width:'100%',display:"block",marginBottom:"5px"}}>{"Warranty Count (" + WarrantyData?.warranty_count + ")"}</span>
              <span>{"Warranty Used (" + WarrantyData?.warranty_used + ")"}</span>
            </div> */}
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Units</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Pest</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Service</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Pest 2</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Service 2</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Pest 3</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Service 3</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Pest 4</Table.HeaderCell>
                  <Table.HeaderCell>Warranty Service 4</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!var_mdl_progress && WarrantyData?.warranty_unit_data?.map((item, index) => (
                  <Table.Row key={index}>
                     <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="green"
                          onClick={() => goToUpdateWarranty(item)}
                        >
                          Edit
                        </Label>
                      </Table.Cell>
                    <Table.Cell>{item.unit}</Table.Cell>
                    <Table.Cell>{item.is_on_pest === 1 && item.is_on_service === 0 ? moment(item.warranty_date).format("YYYY-MM-DD") : item.is_on_pest === 0 && item.is_on_service === 1 ? moment(item.warranty_date_service).format("YYYY-MM-DD") : moment(item.warranty_date).format("YYYY-MM-DD")}</Table.Cell>
                    <Table.Cell>{item.warranty_pest}</Table.Cell>
                    <Table.Cell>{item.warranty_service}</Table.Cell>
                    <Table.Cell>{item.warranty_pest_2}</Table.Cell>
                    <Table.Cell>{item.warranty_service_2}</Table.Cell>
                    <Table.Cell>{item.warranty_pest_3}</Table.Cell>
                    <Table.Cell>{item.warranty_service_3}</Table.Cell>
                    <Table.Cell>{item.warranty_pest_4}</Table.Cell>
                    <Table.Cell>{item.warranty_service_4}</Table.Cell>
                  </Table.Row>
                ))}
                {!var_mdl_progress && WarrantyData?.warranty_unit_data?.length === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan="10">
                      <div className="no-data-found">
                   
                        <div className="no-data-found-text" style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}>
                          No Data 
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
        <div className="modal-footer">
          <Button type="submit" color="red"  onClick={() => set_mdl_open(false)}>
            Close
          </Button>
        </div>
      </div>
      {/***** MODAL: Warrenty Detail **************************************************************************/}
      <Modal
        id="mdl-warranty_detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        size={"large"}
        open={var_mdl_warranty_detail_open}
        onClose={() => set_mdl_warranty_detail_open(false)}
      >
        <MDL_WARRANTY_DETAILS
          building_id={building_id}
          set_mdl_open={set_mdl_warranty_detail_open}
          units_array={buildingUnitInfo}
        ></MDL_WARRANTY_DETAILS>
      </Modal>
      {/***** END MODAL: Warrenty Detail **************************************************************************/}
       {/***** MODAL:Update Warrenty Detail **************************************************************************/}
       <Modal
        id="mdl-warranty_detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        size={"large"}
        open={var_mdl_warranty_update_open}
        onClose={() => set_mdl_warranty_update_open(false)}
      >
        <MDL_UPDDATE_WARRANTY_DETAILS
          WarrantyItemData={WarrantyItemData}
          set_mdl_open={set_mdl_warranty_update_open}
          units_array={buildingUnitInfo}
        ></MDL_UPDDATE_WARRANTY_DETAILS>
      </Modal>
      {/***** END MODAL: Warrenty Detail **************************************************************************/}
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
       {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
       <Modal
        id="mdl-confirmation"
        dimmer={"inverted"}
        open={var_mdl_confirmation}
        onClose={() => set_mdl_confirmation(false)}
      >
        <MDL_CONFIRMATION
          set_mdl_open={set_mdl_confirmation}
          var_modaltitle="Delete All Warranty"
          var_message="Are you sure want to delete all warranty?"
          confirmation_function={delete_all_warranty}
        ></MDL_CONFIRMATION>
      </Modal>

      {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
    </>
  );
}
