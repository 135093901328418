import React, { useContext, useState, useEffect } from 'react';
import { Table, Label, Modal,Search } from 'semantic-ui-react';
import { AppContext } from '../../../Context/AppContext';
import { useHistory } from 'react-router-dom';
import MDL_PROGRESS from '../../../components/cmp-progress/cmp-progress';
import { acceptOrRejectOrder, getAdminDraftRequests, getAdminFollowUpRequests } from '../../../constants';
import AppPagination from '../../../components/cmp-pagination/Pagination';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const DraftRequestsAdmin=()=>{


    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const{userInfo}=useContext(AppContext)
    const[user,setUser]=userInfo;
    const [orders,setOrders] = useState([]);
    const [ page,setPage ] = useState(1);
    const [ limit,setLimit ] = useState(10);
    const [ searchResult,setSearchResult ] = useState([]);
    const[,setTotalOrders]=useState(0)
    const[var_mdl_progress,set_mdl_progress]=useState(false);



    /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
    useEffect(() => {
		getFollowupRequests(page,limit);
	}, [page,limit])


    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  
    async function getFollowupRequests(page_no,limit_range){
        set_mdl_progress(true);
        await fetch(getAdminDraftRequests+`?page=${page_no?page_no: page}&limit=${limit_range?limit_range: limit}`)
        .then(response=>response.json())
        .then(res=>{
            var ordersFetch=[];
            setTotalOrders(res.body.total_order);
            if(res.statusCode===200){
                ordersFetch=res.body.data
                // if(res.body && res.body.data && res.body.data.length === 0){
                //     setPage(page-1)
                // }
            }
            else{
                ordersFetch=[]
            }
            if( (page && page >1) || (page_no && page_no >1) )
            ordersFetch=[...orders,...ordersFetch];
            setOrders(ordersFetch);

        })
        .catch(error=>{
            console.error("Error:",error);
        })
        set_mdl_progress(false);
    }

    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

    function doSearch(event){
        const value=event.target.value.toString().toLowerCase();
        if(value===""){
            setSearchResult([]);
        }
        else{
            var dummySearchData=[];
            orders.map(val=>{
                if( val.building_name.toString().toLowerCase().includes(value)
                || val.customer_name.toString().toLowerCase().includes(value)
                ){
                    dummySearchData=[...dummySearchData,val];
                }
            })
            setSearchResult(dummySearchData);
        }

    }


    /* RENDER APP
	---------------------------------------------------------------------------------*/

    return(
    <>
        <div className="app-content">
            <div className="app-card">
                <div className="app-card-header followup-requests-header">
                    <div className="app-card-title">Draft Request List ({orders.length})</div>
                    <div className="app-content-search">
                            <Search placeholder="Search Building Name" onSearchChange={value=>doSearch(value)} minCharacters={20000}/>
                    </div>
                </div>

                <div className="app-table-wrapper">
                    <Table unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell title="Building">Building</Table.HeaderCell>
                                <Table.HeaderCell title="Customer Name">Customer</Table.HeaderCell>
                                <Table.HeaderCell title="No. of units">Units</Table.HeaderCell>
                                <Table.HeaderCell title="Date of request">Requested Date</Table.HeaderCell>
                                <Table.HeaderCell title="Current request Status">Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            {!var_mdl_progress && searchResult.length===0 && orders && orders.map((order, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>{order.building_name}</Table.Cell>
                                    <Table.Cell>{order.customer_name}</Table.Cell>
                                    <Table.Cell>{order.units.length}</Table.Cell>
                                    <Table.Cell>{moment(order.date).format("MM-DD-YYYY hh:mm")}</Table.Cell>
                                    <Table.Cell className="td-status">
                                        {order.status === 'draft' &&
                                            <div style={{color: '#FA8C16'}}>Draft</div>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {searchResult.length>0 && searchResult.map((order, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>{order.building_name}</Table.Cell>
                                    <Table.Cell>{order.customer_name}</Table.Cell>
                                    <Table.Cell>{order.units.length}</Table.Cell>
                                    <Table.Cell>{moment(order.date).format("MM-DD-YYYY hh:mm")}</Table.Cell>
                                    <Table.Cell className="td-status">
                                        {order.status === 'draft' &&
                                            <div style={{color: '#FA8C16'}}>Draft</div>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
                {!var_mdl_progress && searchResult.length === 0 && orders.length===0 &&
                    <div className="nodata-placeholder">
                        There is no follow up request to display.
                    </div>
                }
            </div>
            <AppPagination limit={limit} setLimit={setLimit} page={page} setPage={setPage} rowsTotal={orders.length}/>
        </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
        <Modal id="mdl-progress"
            dimmer={'inverted'}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}>
            <MDL_PROGRESS
                set_mdl_open = {set_mdl_progress}
                var_modaltitle = "Please wait"
                var_message = {"Please wait..."}
            >
            </MDL_PROGRESS>
        </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
</>
    )
}

export default DraftRequestsAdmin;